import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Keycloak from 'keycloak-js'
import config from 'config'
import localStorage from 'local-storage'


type AuthContext = {
  token: string | null
  shortToken: string | null
  login: () => void
  logout: () => void
  register: () => void
}

const shortenToken = (token: string) => token ? token.slice(0, 3) + '...' + token.slice(token.length - 3) : ''

const Context = React.createContext<AuthContext>(null)

const useAuth = () => useContext<AuthContext>(Context)

const isLegacy = config.isLegacyAuth

const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      window.atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    )

    return JSON.parse(jsonPayload)
  }
  catch {
    return null
  }
}

const isTokenValid = (token) => {
  const parsedToken = parseJwt(token)

  if (parsedToken.exp) {
    const isValid = parsedToken.exp > (Date.now() / 1000)

    if (isValid) {
      return true
    }
  }

  return false
}

const checkIsTokenExpired = () => {
  const token = localStorage.getItem('token')

  if (token) {
    const parsedToken = parseJwt(token)

    if (parsedToken.exp) {
      return parsedToken.exp <= (Date.now() / 1000)
    }
  }

  return false
}

const getLocalStorageToken = () => {
  const token = localStorage.getItem('token')

  if (token) {
    const isValid = isTokenValid(token)
    return isValid ? token : null
  }

  return null
}

const resetCookie = () => {
  const cookies = document.cookie.split(';')

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
  }
}

const AuthProvider: React.FC = ({ children }) => {
  const [ token, setToken ] = useState(isLegacy || typeof window === 'undefined' ? null : getLocalStorageToken())

  useEffect(() => {
    if (isLegacy) {
      return
    }

    window.getTokenData = () => {
      if (window.token) {
        const parsedToken = parseJwt(token)

        console.log('[token]', parsedToken)
      }
      else {
        console.log('No token')
      }
    }

    const handleLogin = () => {
      console.log('token updated', shortenToken(window.token))
      setToken(window.token)
    }

    window.addEventListener('login', handleLogin)

    return () => {
      window.removeEventListener('login', handleLogin)
    }
  }, [])

  const kk = useMemo(() => {
    if (__CLIENT__) {
      const isDev = /sc\.rmp\./.test(window.location.host)
      const keyCloack = window.keyCloak || new Keycloak(isDev ? {
        url: 'https://rmp.rusatom.dev/auth',
        realm: 'master',
        clientId: 'smart_city',
      } : {
        url: 'https://smartregion.52gov.ru/auth',
        realm: 'master',
        clientId: 'smart_city',
      })

      window.keyCloak = keyCloack

      return keyCloack as Keycloak
    }
  }, [])

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token)
      window.token = token
    }
  }, [ token ])

  const handleUpdateToken = useCallback(() => {
    kk.updateToken(60 * 10)
      .then(() => {
        console.log('token refreshed', shortenToken(kk.token))
        window.token = kk.token
        setToken(kk.token)
        window.dispatchEvent(new CustomEvent('login'))

        // TIMEOUT TO REFRESH
        const expirationTime = (parseJwt(token).exp * 1000) - Date.now()
        const timeout = expirationTime - 10000 > 0 ? expirationTime - 10000 : 10000

        console.log('set token timeout', { expirationTime, timeout })

        window.tokenUpdateTimeout = setTimeout(() => {
          clearTimeout(window.tokenUpdateTimeout)
          console.log('token refresh by timeout')
          handleUpdateToken()
        }, timeout)

      })
      .catch((error) => {
        window.token = null
        setToken(null)
        console.error(`Failed to refresh token`, error)
      })
  }, [ kk ])

  useEffect(() => {
    if (isLegacy || window.isInit) {
      return
    }

    const isExpired = checkIsTokenExpired()

    if (isExpired) {
      localStorage.removeItem('token')
      resetCookie()
    }

    window.isInit = true

    kk.init({
      flow: 'standard',
      pkceMethod: 'S256',
      responseMode: 'fragment',
      checkLoginIframe: false,
      onLoad: 'check-sso',
    })
      .then((isLoggedIn) => {
        console.log({ isLoggedIn })

        if (isLoggedIn) {
          setToken(kk.token)
          window.token = kk.token
          window.dispatchEvent(new CustomEvent('login'))

          handleUpdateToken()
        }
        else {
          setToken(null)
        }
      })

    // kk.onTokenExpired = () => {
    //   console.log('token expired')
    //   handleUpdateToken()
    // }
  }, [ kk, handleUpdateToken ])

  const handleLogout = useCallback(() => {
    localStorage.removeItem('token')
    resetCookie()
    window.keyCloak.logout()
  }, [])

  const value = useMemo(() => ({
    token,
    shortToken: shortenToken(token),
    login: () => kk.login(),
    logout: handleLogout,
    register: () => kk.register(),
  }), [ token, handleLogout, kk ])

  if (token) {
    console.log(shortenToken(token))
  }

  useEffect(() => {
    if (window.tokenUpdateTimeout && !token) {
      clearTimeout(window.tokenUpdateTimeout)
    }
  }, [ token, handleUpdateToken ])

  if (isLegacy) {
    return (
      <Context.Provider value={{}}>
        {children}
      </Context.Provider>
    )
  }

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}


export {
  AuthProvider,
  useAuth,
}
